





































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IUserType } from "@/interfaces/userType"
import { IAddUserRequestParams } from "@/interfaces/addUserRequestParams";
import { IUpdateUserRequestParams } from "@/interfaces/updateUserRequestParams";
import { IUserAccount } from "@/interfaces/userAccount";
import { ICogBasket } from "@/interfaces/cogBasket";
import { IUser } from "@/interfaces/user";
import moment from 'moment';
import { UserTypes } from '../../../../utilities/Enums/UserTypes';
import ToastService from '@/services/toastService';

const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const Auth = namespace("Auth");
const Admin = namespace("Admin");
const CostOfGasReports = namespace("CostOfGasReports");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepAddUser extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;
    @Prop({ type: Boolean, default: false }) readonly inEditMode!: boolean;

    private firstName = '';
    private lastName = '';
    private telephone = '';
    private emailAddress = '';
    private confirmEmailAddress = '';

    private selectedAccountType = '';

    private gasFieldName = 'gasAccount'
    private powerFieldName = 'powerAccount'

    private selectedAccounts = Array<any>();
    private selectedPowerAccounts = Array<any>();
    private selectedAccount = '';
    private selectedPowerAccount = '';
    private searchString = '';

    private isSingleAccountType = false;

    private internalUserIsSelected = false;
    private isExternalUserSelected = false;
    private isTpiSelected = false;

    private selectedBaskets = Array<any>();
    private selectedBasketId: (number | null) = null;

    private showSuccessMessage = false;
    private emailSentTo: (string | null) = null;

    private userExistsStatus = false;
    private userEmail = '';

    private disableTooltip = false;

    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;

    private accountTypeOptions: string[] = [];

    private showGasAccountsError = false;
    private showPowerAccountsError = false;

    private visibleUserDetails = true;
    private visibleGas = true;
    private visiblePower = true;
    private visibleBaskets = true;

    private UserTypeLabel = '';

    private gasAccountsCurrentPage = 1;
    private gasAccountsPerPage = 10;
    private filterOn_Gas = ['AccountNumber', 'DisplayLabel'];
    private totalRows_Gas = 0;

    private powerAccountsCurrentPage = 1;
    private powerAccountsPerPage = 10;
    private filterOn_Power = ['AccountNumber', 'DisplayLabel'];
    private totalRows_Power = 0;

    private basketsCurrentPage = 1;
    private basketsPerPage = 10;
    private filterOn_Baskets = ['Name'];
    private totalRows_Baskets = 0;

    private filterGasAccounts = '';
    private filterGasAccounts_Search = '';

    private filterPowerAccounts = '';
    private filterPowerAccounts_Search = '';

    private filterBaskets = '';
    private filterBaskets_Search = '';

    private selectedAccountForTpiUser: any;
    private tpiAccessUntilDateEditMode = '';

    private gasAccountsLoading = true;
    private powerAccountsLoading = true;
    private basketsLoading = true;

    private showTableEmptyMessage = this.inEditMode ? true : false;

    private addPowerAccountGroupLabel = this.inEditMode ? 'Add group account' : 'Power group account(s)';
    private addBasketGroupLabel = this.inEditMode ? 'Add basket' : 'Basket(s)';

    private isAddingGasAccount = false;

    public accountFields = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until',
            headerTitle: 'Access until'
        },
        {
            key: 'Delete'
        }
    ];

    private userLocked = false;

    //May need it later
    public accountFieldsStandard = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until',
            headerTitle: 'Access until',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'Delete'
        }
    ];

    public accountFieldsPower = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Group account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until',
            headerTitle: 'Access until'
        },
        {
            key: 'Delete'
        }
    ];

    public accountFieldsWithAsterisk = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until *',
            headerTitle: 'Access until *'
        },
        {
            key: 'Delete'
        }
    ];

    public accountFieldsWithAsteriskPower = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Group account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until *',
            headerTitle: 'Access until *'
        },
        {
            key: 'Delete'
        }
    ];

    public accountFieldsStandardPower = [
        {
            key: 'AccountNumber',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'DisplayLabel',
            label: 'Group account'
        },
        {
            key: 'AllowAccessUntil',
            label: 'Access until',
            headerTitle: 'Access until',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'Delete'
        }
    ];

    public basketTableFields = [
        {
            key: 'Name',
            label: 'Basket',
        },
        {
            key: 'Delete',
            label: 'Delete'
        }
    ];


    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @Admin.Getter
    private getSelectedUserForManage!: IUser;

    @Admin.Getter
    private getManageUserTypes!: IUserType[];

    @Admin.Action
    private getManageUserTypesAction!: (params: { userId: string }) => Promise<void>;

    @CostOfGasReports.Getter
    private getBaskets!: ICogBasket[];

    @CostOfGasReports.Action
    private fetchBaskets!: (params: { userId: string }) => Promise<void>;

    @Auth.Getter
    private getUserId!: string;

    @Admin.Action
    private addUser!: (params: IAddUserRequestParams) => Promise<void>;

    @Admin.Action
    private updateUser!: (params: IUpdateUserRequestParams) => Promise<void>;

    @Admin.Action
    private userExists!: (params: { userId: string, email: string }) => Promise<boolean>;

    @Admin.Action
    private searchUserAccounts!: (params: { userId: string, query: string, appendResult: boolean }) => Promise<void>;

    @Admin.Getter
    private getUserAccounts!: Array<IUserAccount>;

    @Admin.Mutation
    private resetAccountsSearch!: (accountNumber: string) => void;

    @Admin.Action
    private fetchUserAccounts!: (params: { userId: string, accountNumberQuery: string | null }) => Promise<void>;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @GasAccounts.Getter
    private getIsMorePages!: boolean;

    @Admin.Getter
    private getPowerAccountsCount!: number;

    @GasAccounts.Action
    private fetchUserAccountsCount!: (userId: string) => Promise<void>;

    @Admin.Action
    private fetchPowerUserAccounts!: (params: { userId: string, groupAccountNumberQuery: string | null }) => Promise<void>;

    @Admin.Action
    private searchPowerUserAccounts!: (params: { userId: string, groupAccountNumberQuery: string, appendResult: boolean }) => Promise<void>;

    @Admin.Getter
    private getPowerUserAccounts!: Array<IUserAccount>;

    @Admin.Action
    private saveSelectedUserIdForManageAction!: (params: { userId: string }) => Promise<void>;

    @Admin.Action
    private updateUserPreload!: (params: { userId: string, userIdEdit: string }) => Promise<void>;

    @Admin.Action
    private updateUserPreloadBaskets!: (params: { userId: string, requestingUserId: string }) => Promise<void>;

    @Admin.Action
    private removeAccountGas_Update!: (params: { userId: string, targetUserId: string, accountNumber: string }) => Promise<boolean>;

    @Admin.Action
    private updateAccountGas_Update!: (params: IUpdateUserRequestParams) => Promise<boolean>;

    @Admin.Action
    private addAccountGas_Update!: (params: IUpdateUserRequestParams) => Promise<boolean>;

    @Admin.Action
    private removeAccountPower_Update!: (params: { userId: string, targetUserId: string, accountNumber: string }) => Promise<boolean>;

    @Admin.Action
    private updateAccountPower_Update!: (params: IUpdateUserRequestParams) => Promise<boolean>;

    @Admin.Action
    private addAccountPower_Update!: (params: IUpdateUserRequestParams) => Promise<boolean>;

    @Admin.Action
    private removeBaskets_Update!: (params: { userId: string, targetUserId: string, basketId: number }) => Promise<boolean>;

    @Admin.Action
    private addBaskets_Update!: (params: IUpdateUserRequestParams) => Promise<boolean>;

    @Auth.Getter
    private canManageStandardUser!: boolean;

    @Auth.Getter
    private canManageExternalUser!: boolean;

    private addUserRequestParams: IAddUserRequestParams =
        {
            FirstName: null,
            Surname: null,
            Telephone: null,
            Email: null,
            ConfirmEmail: null,
            SelectedGasAccounts: null,
            SelectedPowerAccounts: null,
            SelectedBasketIds: null,
            TotalAvailableAccounts: null,
            SelectedUserTypeId: null,
            SelectedUserTypeName: null,
            UserId: null
        }

    private updateUserRequestParams: IUpdateUserRequestParams =
    {
        FirstName: null,
        Surname: null,
        Telephone: null,
        Email: null,
        ConfirmEmail: null,
        SelectedGasAccounts: null,
        SelectedPowerAccounts: null,
        SelectedBasketIds: null,
        TotalAvailableAccounts: null,
        UserId: null,
        UserIdEdit: null,
        UserTypeId: null,
        IsLocked: null
    }

    onFormSubmit() {
        this.showSuccessMessage = false;
        this.showGasAccountsError = false;
        this.showPowerAccountsError = false;

        if (this.inEditMode) {
            var validationFailed = false;
            var validationResult = this.EditModeValidateFields();

            validationResult.then((data) => {
                if (!data) {
                    validationFailed = true;
                    return;
                }

                if (!validationFailed) {
                    if (this.getSelectedUserForManage.EmailAddress !== this.emailAddress) {
                        this.userExists({ userId: this.getUserId, email: this.emailAddress }).then((data: any) => {
                            this.userExistsStatus = data;
                            this.userEmail = this.emailAddress;

                            if (this.userExistsStatus) {
                                return;
                            }

                            this.EditUser();
                        });
                    } else {
                        this.EditUser();
                    }
                }
            })
        }
        else if (!this.inEditMode) {
            this.$validator.validateAll().then((isValid) => {

                var validationFailed = false;
                if (!isValid && !this.inEditMode) {
                    validationFailed = true;
                }

                if (this.isExternalUserSelected || this.isSingleAccountType || this.IsAdminAndEditingExternalTpiOrStandardUser) {
                    if (!this.inEditMode && this.selectedAccounts.length === 0 && this.selectedPowerAccounts.length === 0) {
                        validationFailed = true;
                        this.showGasAccountsError = true;
                        this.showPowerAccountsError = true;
                    }
                }

                if (validationFailed == true) { return; }
                    this.userExists({ userId: this.getUserId, email: this.emailAddress }).then((data: any) => {
                        this.userExistsStatus = data;
                        this.userEmail = this.emailAddress;

                        if (this.userExistsStatus) {
                            return;
                        }
                        var selectedUserType = this.getManageUserTypes.filter(x => x.Name == this.selectedAccountType)[0];
                        const selectedUserTypeId = selectedUserType.TypeId;
                        const selectedUserTypeName = selectedUserType.Name;

                        this.addUserRequestParams.FirstName = this.firstName;
                        this.addUserRequestParams.Surname = this.lastName;
                        this.addUserRequestParams.Telephone = this.telephone;
                        this.addUserRequestParams.Email = this.emailAddress;
                        this.addUserRequestParams.ConfirmEmail = this.confirmEmailAddress;
                        this.addUserRequestParams.SelectedGasAccounts = this.selectedAccounts.map(d => ({
                            AccountNumber: d.AccountNumber,
                            AllowAccessUntil: d.AllowAccessUntil
                        }));
                        this.addUserRequestParams.SelectedPowerAccounts = this.selectedPowerAccounts.map(d => ({
                            //GroupId: d.GroupName,
                            AccountNumber: d.AccountNumber,
                            AllowAccessUntil: d.AllowAccessUntil
                        }));
                        this.addUserRequestParams.SelectedBasketIds = this.selectedBaskets.map(d => d.Id);
                        this.addUserRequestParams.SelectedUserTypeId = selectedUserTypeId;
                        this.addUserRequestParams.SelectedUserTypeName = selectedUserTypeName;
                        this.addUserRequestParams.UserId = this.getUserId;

                        this.addUser(this.addUserRequestParams).then((data: any) => {

                            this.emailSentTo = this.addUserRequestParams.Email;
                            this.showSuccessMessage = data;

                            this.addUserRequestParams.FirstName = '';
                            this.addUserRequestParams.Surname = '';
                            this.addUserRequestParams.Telephone = '';
                            this.addUserRequestParams.Email = '';
                            this.addUserRequestParams.ConfirmEmail = '';
                            this.addUserRequestParams.UserId = '';
                            this.addUserRequestParams.SelectedGasAccounts = [];
                            this.addUserRequestParams.SelectedPowerAccounts = [];
                            this.addUserRequestParams.SelectedBasketIds = [];

                            this.firstName = '';
                            this.lastName = '';
                            this.telephone = '';
                            this.emailAddress = '';
                            this.confirmEmailAddress = '';
                            this.selectedAccounts = [];
                            this.selectedPowerAccounts = [];
                            this.selectedBaskets = [];

                            this.$validator.reset();
                            this.showGasAccountsError = false;
                            this.showPowerAccountsError = false;
                        });

                    });
            });
        }


    }

    private async EditModeValidateFields(): Promise<boolean> {
        const results = Promise.all([
            this.$validator.validate('firstName'),
            this.$validator.validate('lastName'),
            this.$validator.validate('telephone'),
            this.$validator.validate('emailAddress')
        ]);

        const areValid = (await results).every(isValid => isValid);

        return areValid;
    }

    EditUser() {
        this.updateUserRequestParams.FirstName = this.firstName;
        this.updateUserRequestParams.Surname = this.lastName;
        this.updateUserRequestParams.Telephone = this.telephone;
        this.updateUserRequestParams.Email = this.emailAddress;
        this.updateUserRequestParams.ConfirmEmail = this.confirmEmailAddress;
        this.updateUserRequestParams.IsLocked = this.userLocked;
        if (this.canManageStandardUser || this.IsAdminAndEditingExternalTpiOrStandardUser) {
            this.updateUserRequestParams.SelectedGasAccounts = this.selectedAccounts.map(d => ({
                AccountNumber: d.AccountNumber,
                AllowAccessUntil: d.AllowAccessUntil
            }));
            this.updateUserRequestParams.SelectedPowerAccounts = this.selectedPowerAccounts.map(d => ({
                AccountNumber: d.AccountNumber,
                AllowAccessUntil: d.AllowAccessUntil
            }));
        }

        if (this.IsAdminAndEditingExternalTpiOrStandardUser) {
            this.updateUserRequestParams.SelectedBasketIds = this.selectedBaskets.map(d => d.Id);
        }
      
        this.updateUserRequestParams.UserId = this.getUserId;
        this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
        this.updateUserRequestParams.UserTypeId = this.getSelectedUserForManage.UserTypeId;

        this.updateUser(this.updateUserRequestParams).then((data: any) => {

            this.emailSentTo = this.updateUserRequestParams.Email;
            this.showSuccessMessage = data;

            this.updateUserRequestParams.FirstName = '';
            this.updateUserRequestParams.Surname = '';
            this.updateUserRequestParams.Telephone = '';
            this.updateUserRequestParams.Email = '';
            this.updateUserRequestParams.ConfirmEmail = '';
            this.updateUserRequestParams.UserId = '';
            this.updateUserRequestParams.IsLocked = false;

            this.showGasAccountsError = false;
            this.showPowerAccountsError = false;
            this.userExistsStatus = false;
        });
    }

    onSetSelectedOption(option: any) {
        this.selectedAccountType = option;
        let selectedUserType = this.getManageUserTypes.filter(x => x.Name == this.selectedAccountType)[0];
        
        if (selectedUserType != null) {
            this.internalUserIsSelected = selectedUserType.IsInternal;

            this.isExternalUserSelected = !selectedUserType.IsInternal && selectedUserType.Name != "Standard";

            this.isTpiSelected = !selectedUserType.IsInternal && selectedUserType.Name === "TPI";

            if (this.isExternalUserSelected || this.isSingleAccountType) {
                this.fetchUserAccounts({ userId: this.getUserId, accountNumberQuery: this.searchString });
                this.fetchPowerUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: null });
            }
        }
    }

    beforeMount() {
        const dict = {
            custom: {
                firstName: {
                    required: 'First name is required'
                },
                lastName: {
                    required: 'Last name is required'
                },
                telephone: {
                    required: 'Telephone is required'
                },
                emailAddress: {
                    required: 'Email is required',
                    email: 'Please enter a full valid email address'
                },
                confirmEmailAddress: {
                    required: 'Confirm email is required',
                    email: 'Please enter a full valid email address'
                },
                tpiAccessUntilDateEditMode: {
                    required: 'Access until date is required'
                }
            }
        };

        this.$validator.localize('en', dict);

        if (this.inEditMode) {
            this.visibleGas = false;
            this.visiblePower = false;
            this.visibleBaskets = false;
        }
    }

    mounted(): void {
        this.getManageUserTypesAction({ userId: this.getUserId }).then(() => {
            this.getManageUserTypes.forEach(c => { this.accountTypeOptions.push(c.Name) });

            this.onSetSelectedOption(this.accountTypeOptions[0]);

            if (this.getManageUserTypes.length === 1) {
                this.isSingleAccountType = true;
                this.fetchUserAccounts({ userId: this.getUserId, accountNumberQuery: this.searchString });
                this.fetchPowerUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: null });
            }

            if (this.inEditMode) {
                if (this.getSelectedUserForManage != null) {
                    this.firstName = this.getSelectedUserForManage.FirstName;
                    this.lastName = this.getSelectedUserForManage.LastName;
                    this.telephone = this.getSelectedUserForManage.PhoneNumber;
                    this.emailAddress = this.getSelectedUserForManage.EmailAddress;
                    this.selectedAccountType = this.getSelectedUserForManage.UserTypeName;
                    this.userLocked = this.getSelectedUserForManage.IsLocked;

                    if (this.canManageStandardUser || (this.IsAdminAndEditingExternalTpiOrStandardUser)) {

                        if (this.IsAdminAndEditingExternalTpiOrStandardUser) {
                            this.preloadDataForGazpromAdminWhenEditingStandardOrExternalUser();
                        }

                        this.updateUserPreload({userId: this.getUserId, userIdEdit: this.getSelectedUserForManage.Id}).then((data: any)  => {
                            if (data?.SelectedGasAccounts !== null && data?.SelectedGasAccounts.length > 0) {
                                data?.SelectedGasAccounts.forEach(x => {
                                    var newSelectedAccount = {
                                        AccountNumber: x.AccountNumber,
                                        DisplayLabel: x.DisplayLabel,
                                        AllowAccessUntil: this.isSingleAccountType || this.IsAdminAndEditingExternalTpiOrStandardUser ? x.AllowAccessUntil : '',
                                        Delete: null
                                    };

                                    this.selectedAccounts.push(newSelectedAccount);
                                });
                                this.totalRows_Gas = this.selectedAccounts.length;
                                this.gasAccountsLoading = false;
                            } else {
                                this.gasAccountsLoading = false;
                            }

                            if (data?.SelectedPowerAccounts !== null && data?.SelectedPowerAccounts.length > 0) {
                                data?.SelectedPowerAccounts.forEach(x => {
                                    var newSelectedAccount = {
                                        AccountNumber: x.AccountNumber,
                                        DisplayLabel: x.DisplayLabel,
                                        AllowAccessUntil: this.isSingleAccountType || this.IsAdminAndEditingExternalTpiOrStandardUser ? x.AllowAccessUntil : '',
                                        Delete: null
                                    };

                                    this.selectedPowerAccounts.push(newSelectedAccount);
                                });
                                this.totalRows_Power = this.selectedPowerAccounts.length;
                                this.powerAccountsLoading = false;
                            } else {
                                this.powerAccountsLoading = false;
                            }
                        });
                    }
                }
            }

            if (!this.isSingleAccountType) {
                this.loadBaskets();
            }
        });

        this.UserTypeLabel = this.inEditMode && this.IsAdminAndEditingExternalTpiOrStandardUser ? "User type" : "User type *";
    }

    private disableTooltips(): void {
        this.disableTooltip = true;
        this.$root.$emit('bv::hide::tooltip')
    }

    private enableTooltips(): void {
        this.disableTooltip = false;
    }

    private preloadUserAssignedBaskets() {
        this.updateUserPreloadBaskets({ userId: this.getUserId, requestingUserId: this.getSelectedUserForManage.Id }).then((data: any) => {
            data?.forEach(x => {
                var newSelectedBasket = {
                    Id: x.Id,
                    Name: x.Name,
                    Delete: null
                };

                this.selectedBaskets.push(newSelectedBasket);
            });
        });
    }

    private preloadDataForGazpromAdminWhenEditingStandardOrExternalUser() {
        this.internalUserIsSelected = false;
        this.fetchUserAccountsCount(this.getUserId);
        this.fetchUserAccounts({ userId: this.getUserId, accountNumberQuery: this.searchString });
        this.fetchPowerUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: null });
        this.preloadUserAssignedBaskets();
    }

    async onSetSelectedAccount(accountNumber: string) {
        var selectedUserAccount = this.getUserAccounts.filter(x => x.AccountNumber === accountNumber)[0];

        var isAccountAlreadySelected = this.selectedAccounts.filter(x => x.AccountNumber === selectedUserAccount.AccountNumber).length > 0;

        if (this.inEditMode) {
            if (isAccountAlreadySelected) {
                var ToastSuccessMessageConfig = ToastService.ErrorMessageDefaults();
                ToastSuccessMessageConfig.autoHideDelay = 2000;
                this.$bvToast.toast('Account already added', ToastSuccessMessageConfig);
            } else if (this.IsAdminAndEditingTpiUser){
                this.selectedAccountForTpiUser = selectedUserAccount;
                this.isAddingGasAccount = true;
                this.$bvModal.show('accessUntilDateModal');
            } else {
                this.addAccountToUserEditMode(selectedUserAccount);
            }
        } else {
            if (!isAccountAlreadySelected) {
                var newSelectedAccount = {
                    AccountNumber: selectedUserAccount.AccountNumber,
                    DisplayLabel: selectedUserAccount.DisplayLabel,
                    AllowAccessUntil: this.isSingleAccountType ? selectedUserAccount.AllowAccessUntil : '',
                    Delete: null
                };

                this.selectedAccounts.push(newSelectedAccount);
                this.showPowerAccountsError = false;
                this.showGasAccountsError = false;
            }
        }
    }

    private async addAccountToTpiUser(isAddingGasAccount: boolean) {

        const results = Promise.all([
            this.$validator.validate('tpiAccessUntilDateEditMode'),
        ]);

        const areValid = (await results).every(isValid => isValid);
        if (areValid) {
            if (isAddingGasAccount) {
                this.addAccountToUserEditMode(this.selectedAccountForTpiUser as IUserAccount);
                this.tpiAccessUntilDateEditMode = '';
                this.$bvModal.hide('accessUntilDateModal');
            } else {
                this.addAccountToUserEditMode_Power(this.selectedAccountForTpiUser as IUserAccount);
                this.tpiAccessUntilDateEditMode = '';
                this.$bvModal.hide('accessUntilDateModal');
            }
        }
    }

    private addAccountToUserEditMode(selectedUserAccount: IUserAccount) {
        var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();

        this.updateUserRequestParams.UserId = this.getUserId;
        this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
        this.updateUserRequestParams.SelectedGasAccounts = Array<any>();
        this.updateUserRequestParams.SelectedGasAccounts.push({
            AccountNumber: selectedUserAccount.AccountNumber,
            AllowAccessUntil: this.isSingleAccountType ? selectedUserAccount.AllowAccessUntil : this.IsAdminAndEditingTpiUser ? this.tpiAccessUntilDateEditMode : ''
        });
        ToastSuccessMessageConfig.autoHideDelay = 2000;
        this.addAccountGas_Update(this.updateUserRequestParams).then((data) => {
            if (data) {
                var newSelectedAccount = {
                    AccountNumber: selectedUserAccount.AccountNumber,
                    DisplayLabel: selectedUserAccount.DisplayLabel,
                    AllowAccessUntil: this.updateUserRequestParams.SelectedGasAccounts[0].AllowAccessUntil,
                    Delete: null
                };

                this.selectedAccounts.unshift(newSelectedAccount);
                this.showPowerAccountsError = false;
                this.showGasAccountsError = false;
                this.$bvToast.toast('Account added successfully', ToastSuccessMessageConfig);
                this.gasAccountsCurrentPage = 1;
                this.totalRows_Gas = this.selectedAccounts.length;
            }
        });
    }

    private addAccountToUserEditMode_Power(selectedUserAccount: IUserAccount) {
        var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();

        var isAccountAlreadySelected = this.selectedPowerAccounts.filter(x => x.AccountNumber === selectedUserAccount.AccountNumber).length > 0;
        if (!isAccountAlreadySelected) {
            this.updateUserRequestParams.UserId = this.getUserId;
            this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
            this.updateUserRequestParams.SelectedPowerAccounts = Array<any>();
            this.updateUserRequestParams.SelectedPowerAccounts.push({
                AccountNumber: selectedUserAccount.AccountNumber,
                AllowAccessUntil: this.isSingleAccountType ? selectedUserAccount.AllowAccessUntil : this.IsAdminAndEditingTpiUser ? this.tpiAccessUntilDateEditMode : ''
            });
            ToastSuccessMessageConfig.autoHideDelay = 2000;
            this.addAccountPower_Update(this.updateUserRequestParams).then((data) => {
                if (data) {
                    var newSelectedAccount = {
                        AccountNumber: selectedUserAccount.AccountNumber,
                        DisplayLabel: selectedUserAccount.DisplayLabel,
                        AllowAccessUntil: this.updateUserRequestParams.SelectedPowerAccounts[0].AllowAccessUntil,
                        Delete: null
                    };

                    this.selectedPowerAccounts.unshift(newSelectedAccount);
                    this.showPowerAccountsError = false;
                    this.showGasAccountsError = false;
                    this.$bvToast.toast('Account added successfully', ToastSuccessMessageConfig);
                    this.powerAccountsCurrentPage = 1;
                    this.totalRows_Power = this.selectedPowerAccounts.length;
                }
            });
        } else {
            ToastSuccessMessageConfig = ToastService.ErrorMessageDefaults();
            ToastSuccessMessageConfig.autoHideDelay = 2000;
            this.$bvToast.toast('Account already added', ToastSuccessMessageConfig);
        }
    }

    async onSetPowerSelectedAccount(accountNumber: string) {
        var selectedUserAccount = this.getPowerUserAccounts.filter(x => x.AccountNumber === accountNumber)[0];

        if (this.inEditMode) {
            if (this.IsAdminAndEditingTpiUser) {
                this.selectedAccountForTpiUser = selectedUserAccount;
                this.isAddingGasAccount = false;
                this.$bvModal.show('accessUntilDateModal');
            } else {
                this.addAccountToUserEditMode_Power(selectedUserAccount);
            }
        } else {
            var isAccountAlreadySelected = this.selectedPowerAccounts.filter(x => x.AccountNumber === accountNumber).length > 0;
            if (!isAccountAlreadySelected) {
                var newSelectedAccount = {
                    AccountNumber: selectedUserAccount.AccountNumber,
                    DisplayLabel: selectedUserAccount.DisplayLabel,
                    AllowAccessUntil: this.isSingleAccountType ? selectedUserAccount.AllowAccessUntil : '',
                    Delete: null
                };

                this.selectedPowerAccounts.push(newSelectedAccount);
                this.showGasAccountsError = false;
                this.showPowerAccountsError = false;
            }
        }
    }

    binSelectedAccount(accountNumber: string) {
        if (this.inEditMode) {
            this.removeAccountGas_Update({ userId: this.getUserId, targetUserId: this.getSelectedUserForManage.Id, accountNumber: accountNumber }).then((data) => {
                if (data) {
                    for (var i = 0; i <= this.selectedAccounts.length; i++) {

                        if (this.selectedAccounts[i].AccountNumber == accountNumber) {
                            this.selectedAccounts.splice(i, 1);
                            break;
                        }
                    }
                    var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();
                    ToastSuccessMessageConfig.autoHideDelay = 2000;
                    this.$bvToast.toast('Account removed from user', ToastSuccessMessageConfig);
                    this.totalRows_Gas = this.selectedAccounts.length;
                }
            });
        } else {
            for (var i = 0; i <= this.selectedAccounts.length; i++) {

                if (this.selectedAccounts[i].AccountNumber == accountNumber) {
                    this.selectedAccounts.splice(i, 1);
                    return;
                }
            }
        }
    }

    binPowerSelectedAccount(accountNumber: string) {
        if (this.inEditMode) {
            this.removeAccountPower_Update({ userId: this.getUserId, targetUserId: this.getSelectedUserForManage.Id, accountNumber: accountNumber }).then((data) => {
                if (data) {
                    for (var i = 0; i <= this.selectedPowerAccounts.length; i++) {

                        if (this.selectedPowerAccounts[i].AccountNumber == accountNumber) {
                            this.selectedPowerAccounts.splice(i, 1);
                            break;
                        }
                    }
                    var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();
                    ToastSuccessMessageConfig.autoHideDelay = 2000;
                    this.$bvToast.toast('Account removed from user', ToastSuccessMessageConfig);
                    this.totalRows_Power = this.selectedPowerAccounts.length;
                }
            });
        } else {
            for (var i = 0; i <= this.selectedPowerAccounts.length; i++) {

                if (this.selectedPowerAccounts[i].AccountNumber == accountNumber) {
                    this.selectedPowerAccounts.splice(i, 1);
                    return;
                }
            }
        }
    }

    public async loadBaskets() {
        await this.fetchBaskets({ userId: this.getUserId }).then(() => {
            this.basketsLoading = false;
            this.totalRows_Baskets = this.selectedBaskets.length;
        }).catch(reason => {
            console.error(reason);
        });
    }

    binSelectedBasket(basketId: string) {
        if (this.inEditMode){
            this.removeBaskets_Update(({ userId: this.getUserId, targetUserId: this.getSelectedUserForManage.Id, basketId: parseInt(basketId) })).then((data) => {
                if (data){
                    for (var i = 0; i <= this.selectedBaskets.length; i++) {
                        if (this.selectedBaskets[i].Id == basketId) {
                            this.selectedBaskets.splice(i, 1);
                            break;
                        }
                    }
                    var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();
                    ToastSuccessMessageConfig.autoHideDelay = 2000;
                    this.$bvToast.toast('Basket removed from user', ToastSuccessMessageConfig);
                    this.totalRows_Baskets = this.selectedBaskets.length;
                }
            });
        } else {
            for (var i = 0; i <= this.selectedBaskets.length; i++) {
                if (this.selectedBaskets[i].Id == basketId) {
                    this.selectedBaskets.splice(i, 1);
                    return;
                }
            }
        }
    }

    addSelectedCogBasket(basketId: number) {
        var selectedBasket = this.getBaskets.filter(x => x.Id === basketId)[0];
        var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();

        var isBasketAlreadySelected = this.selectedBaskets.filter(x => x.Id === basketId).length > 0;
        if (this.inEditMode && !isBasketAlreadySelected){
            this.updateUserRequestParams.UserId = this.getUserId;
            this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
            this.updateUserRequestParams.SelectedBasketIds = Array<number>();
            this.updateUserRequestParams.SelectedBasketIds.push(selectedBasket.Id);
            this.addBaskets_Update(this.updateUserRequestParams).then((data) => {
                if(data){
                    this.selectedBaskets.unshift(selectedBasket);
                    ToastSuccessMessageConfig.autoHideDelay = 2000;
                    this.$bvToast.toast('Basket added successfully', ToastSuccessMessageConfig);
                    this.basketsCurrentPage = 1;
                    this.totalRows_Baskets = this.selectedBaskets.length;
                }
            })
        } else if (!isBasketAlreadySelected) {
            var newSelectedBasket = {
                Id: selectedBasket.Id,
                Name: selectedBasket.Name,
                Delete: null
            };

            this.selectedBaskets.push(newSelectedBasket);
        }
    }

    get isFilterable(): boolean {
        return this.getUserAccountsCount <= 200 ?? false;
    }

    get isFilterablePower(): boolean {
        return this.getPowerAccountsCount <= 200 ?? false;
    }

    get isFilterableBaskets(): boolean {
        return true;
    }

    private async onSearch(search: string, loading: (isLoading: boolean) => void) {
        if (this.isFilterable) {
            loading(false);
            return;
        }

        //this.onClose();

        if (search.length === 0) {
            //this.resetPageNumber();
            await this.fetchUserAccounts({ userId: this.getUserId, accountNumberQuery: null });
            //this.onOpen();
        }
        //}

        this.searchString = search.trim();

        if (search.length > 2) {
            loading(true);
            this.search(this.searchString, loading);
        }
    }

    private async search(search: string, loading: (isLoading: boolean) => void) {
        try {
            //this.resetPageNumber();
            await this.searchUserAccounts({ userId: this.getUserId, query: search, appendResult: false });
            //this.onOpen();
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    }

    private async onPowerSearch(search: string, loading: (isLoading: boolean) => void) {
        if (this.isFilterablePower) {
            loading(false);
            return;
        }

        //this.onClose();

        this.searchString = search;

        if (search.length === 0) {
            //this.resetPageNumber();
            await this.fetchPowerUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: null });
            //this.onOpen();
        }

        if (search.length > 2) {
            loading(true);
            this.powerSearch(search, loading);
        }
    }

    private async powerSearch(search: string, loading: (isLoading: boolean) => void) {
        try {
            //this.resetPageNumber();
            await this.searchPowerUserAccounts({ userId: this.getUserId, groupAccountNumberQuery: search, appendResult: false });
            //this.onOpen();
        } catch (e) {
            console.error(e);
        } finally {
            loading(false);
        }
    }

    private uploadRowClass(row:any, type) {
        if (!row || type !== 'row') return
        if (row.AllowAccessUntil !== "" && moment(row.AllowAccessUntil).isBefore(moment())) {
            return 'row-error';
        }
    }

    private backButtonClicked(option: number) {
        this.$emit("backButtonClicked", option);
    }

    get showWarningMessageIsAdminAndEditingExternalOrTpiUser(): boolean {
        return (this.inEditMode
            && this.isGazpromAdmin
            && this.canManageExternalUser
            && (this.getSelectedUserForManage?.UserTypeId === UserTypes.ExternalAdmin
                || this.getSelectedUserForManage?.UserTypeId === UserTypes.TPI));
    }

    get IsAdminAndEditingExternalTpiOrStandardUser(): boolean {
        return (this.inEditMode
                && this.isGazpromAdmin
                && this.canManageExternalUser
                && (this.getSelectedUserForManage?.UserTypeId === UserTypes.ExternalAdmin
                || this.getSelectedUserForManage?.UserTypeId === UserTypes.TPI
                || this.getSelectedUserForManage?.UserTypeId === UserTypes.Standard));
    }

    get IsAdminAndEditingTpiUser(): boolean {
        return (this.inEditMode
            && this.isGazpromAdmin
            && this.canManageExternalUser
            && (this.getSelectedUserForManage?.UserTypeId === UserTypes.TPI));
    }

    @Watch("firstName")
    @Watch("lastName")
    @Watch("telephone")
    @Watch("emailAddress")
    @Watch("confirmEmailAddress")
    async onCurrentPageChange() {
        if (this.inEditMode && this.showSuccessMessage) {
            this.showSuccessMessage = false;
        }
    }

    @Watch('filterGasAccounts')
    async onFilterTextChange() {
        if (this.filterGasAccounts.length === 0 ) { this.filterGasAccounts_Search = ''; }
    }

    @Watch('filterPowerAccounts')
    async onFilterTextChangePower() {
        if (this.filterPowerAccounts.length === 0) { this.filterPowerAccounts_Search = ''; }
    }

    @Watch('filterBaskets')
    async onFilterTextChangeBaskets() {
        if (this.filterBaskets.length === 0) { this.filterBaskets_Search = ''; }
    }

    get selectedAccountsComputed() {
        return JSON.parse(JSON.stringify(this.selectedAccounts))
    }

    get getSelectableUserAccounts() {
        let selectableUserAccounts = Array<IUserAccount>();
        this.getUserAccounts.forEach((value, index) => {
            if (this.selectedAccounts.find(x => x.AccountNumber === value.AccountNumber)) {
                value.disabled = true;
            } else {
                value.disabled = false;
            }
            selectableUserAccounts.push(value);
        });
        return selectableUserAccounts;
    }

    get getSelectablePowerUserAccounts() {
        let selectableUserAccounts = Array<IUserAccount>();
        this.getPowerUserAccounts.forEach((value, index) => {
            if (this.selectedPowerAccounts.find(x => x.AccountNumber === value.AccountNumber)) {
                value.disabled = true;
            } else {
                value.disabled = false;
            }
            selectableUserAccounts.push(value);
        });
        return selectableUserAccounts;
    }

    get getSelectableBaskets() {
        let selectableBaskets = Array<ICogBasket>();
        this.getBaskets.forEach((value, index) => {
            if (this.selectedBaskets.find(x => x.Id === value.Id)) {
                value.disabled = true;
            } else {
                value.disabled = false;
            }
            selectableBaskets.push(value);
        });
        return selectableBaskets;
    }

    private onAccessDateUpdated_Gas(data: any) {
        if (this.inEditMode) {
            this.updateUserRequestParams.UserId = this.getUserId;
            this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
            this.updateUserRequestParams.SelectedGasAccounts = Array<any>();
            this.updateUserRequestParams.SelectedGasAccounts.push({
                    AccountNumber: data?.AccountNumber,
                    AllowAccessUntil: data?.AllowAccessUntil
                });
            var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();
            ToastSuccessMessageConfig.autoHideDelay = 2000;
            this.updateAccountGas_Update(this.updateUserRequestParams).then((data) => {
                if (data) {
                    this.$bvToast.toast('Access until date amended', ToastSuccessMessageConfig);
                }
            })
        }
    }

    private onAccessDateUpdated_Power(data: any) {
        if (this.inEditMode) {
            this.updateUserRequestParams.UserId = this.getUserId;
            this.updateUserRequestParams.UserIdEdit = this.getSelectedUserForManage.Id;
            this.updateUserRequestParams.SelectedPowerAccounts = Array<any>();
            this.updateUserRequestParams.SelectedPowerAccounts.push({
                    AccountNumber: data?.AccountNumber,
                    AllowAccessUntil: data?.AllowAccessUntil
                });
            var ToastSuccessMessageConfig = ToastService.SuccessMessageDefaults();
            ToastSuccessMessageConfig.autoHideDelay = 2000;
            this.updateAccountPower_Update(this.updateUserRequestParams).then((data) => {
                if (data) {
                    this.$bvToast.toast('Access until date amended', ToastSuccessMessageConfig);
                }
            })
        }
    }

    private searchClickedPower() {
        this.filterPowerAccounts_Search = this.filterPowerAccounts;
    }

    private clearSearchClickedPower() {
        this.filterPowerAccounts = "";
        this.filterPowerAccounts_Search = "";
    }

    onFiltered_Gas(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_Gas = filteredItems.length
        this.gasAccountsCurrentPage = 1
    }

    onFiltered_Power(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_Power = filteredItems.length
        this.powerAccountsCurrentPage = 1
    }

    onFiltered_Baskets(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_Baskets = filteredItems.length
        this.basketsCurrentPage = 1
    }

    dateDisabled(ymd, date) {
        // Return `true` if the date should be disabled
        return date <= moment();
    }

    private clearSearchClicked() {
        this.filterGasAccounts_Search = "";
        this.filterGasAccounts = "";
    }

    private searchClicked() {
        this.filterGasAccounts_Search = this.filterGasAccounts;
    }

    private clearSearchClickedBaskets() {
        this.filterBaskets_Search = "";
        this.filterBaskets = "";
    }

    private searchClickedBaskets() {
        this.filterBaskets_Search = this.filterBaskets;
    }
}
