














import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import { namespace } from 'vuex-class';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");


@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepManageAccountOptions extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: [] }) readonly next_step_id!: string[];
    private selectedOption = 0;

    private adminHref = "";

    @Auth.Getter
    private canManageInternalUser!: boolean;

    @Auth.Getter
    private canManageExternalUser!: boolean;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @Auth.Getter
    private getPortalAuthTokenUrl!: string;

    @Auth.Getter
    private canManageStandardUser!: boolean;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @Auth.Action
    private fetchPortalAuthTokenUrl!: (params: { userId: string }) => Promise<void>;

    @Auth.Getter
    private getUserId!: string;

    setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: any) {
        this.selectedOption = value;
        this.$emit("selectedManageUserOption", value);
    }

    private setAdminLinks() {
        let portalUrl = this.getPortalAuthTokenUrl;
        portalUrl = `${portalUrl}&accountNum=${this.getSelectedAccount}&siteRefNum=${this.getSelectedSiteRefNumber}`;
        this.adminHref = `${portalUrl}&chosenController=Admin&chosenAction=Index`;
    }

    mounted(){
        this.fetchPortalAuthTokenUrl({ userId: this.getUserId }).then(() => {
                this.setAdminLinks();
            }, (error) => {
                console.error(error);
            });
    }
}
