



























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IUsers } from "@/interfaces/users";
import { IUser } from "@/interfaces/user";
import { Claims } from '@/utilities/Claims';
import moment from 'moment';

const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const Auth = namespace("Auth");
const Admin = namespace("Admin");
const CostOfGasReports = namespace("CostOfGasReports");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepManageUsers extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private processing = false;
    private loading = true;
    private currentPage = 1;
    private perPage = 10;
    private searchText = "";
    private isSearchLengthValid = true;
    private isClearSearchVisible = false;
    private showUsersNotFoundError = false;

    public usersFields = [
        {
            key: 'Id',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'Username',
            label: 'Username',
            thClass: 'text-left',
            tdClass: 'text-left pl-3'
        },
        {
            key: 'FirstName',
            label: 'First name'
        },
        {
            key: 'LastName',
            label: 'Last name'
        },
        {
            key: 'LastLoggedIn',
            label: 'Last logged in',
            formatter: this.formatDate
        },
        {
            key: 'IsLocked',
            label: 'Locked'
        },
        {
            key: 'ManageButton',
            label: 'Manage user',
            thClass: 'd-none',
            tdClass: 'p-1'
        }
    ];

    public usersFieldsWithUserType = [
        {
            key: 'Id',
            thClass: 'd-none',
            tdClass: 'd-none'
        },
        {
            key: 'Username',
            label: 'Username',
            thClass: 'text-left',
            tdClass: 'text-left pl-3'
        },
        {
            key: 'FirstName',
            label: 'First name'
        },
        {
            key: 'LastName',
            label: 'Last name'
        },
        {
            key: 'UserTypeName',
            label: 'User type'
        },
        {
            key: 'LastLoggedIn',
            label: 'Last logged in',
            formatter: this.formatDate
        },
        {
            key: 'IsLocked',
            label: 'Locked'
        },
        {
            key: 'ManageButton',
            label: 'Manage user',
            thClass: 'd-none',
            tdClass: 'p-1'
        }
    ];

    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    @Admin.Action
    private getUsersAction!: (params: { userId: string, page: number, size: number, preload: boolean }) => Promise<void>;

    @Admin.Action
    private searchUsersAction!: (params: { searchString: string, userId: string, page: number, size: number }) => Promise<void>;

    @Admin.Action
    private filterUsersAction!: (params: { searchString: string }) => Promise<void>;

    @Admin.Action
    private saveSelectedUserIdForManageAction!: (params: { userId: string }) => Promise<void>;

    @Admin.Action
    private doesClaimExistForUser!: (params: { id: string, claim: string }) => Promise<boolean>;

    @Admin.Action
    private isEditingExternalTpiOrStandardUser!: (params: { id: string }) => Promise<boolean>;

   @Auth.Getter
    private canManageInternalUser!: boolean;

    @Auth.Getter
    private canManageExternalUser!: boolean;

    @Auth.Getter
    private canManageStandardUser!: boolean;

    @Admin.Getter
    private getUsers!: IUsers;

    @Watch("currentPage")
    async onCurrentPageChange() {
        if (this.isGazpromAdmin && this.searchText.length > 2) {
            this.searchUsers();
        } else {
            this.searchText = "";
            this.GetUsers();
        }
    }

    private async GetUsers() {
        this.loading = true;
        await this.getUsersAction({ userId: this.getUserId, page: this.currentPage, size: 10, preload: false }).then(() => {
            this.loading = false;
        });
    }

    private searchClicked() {
        if (!this.validateSearchLength()) { return; }
        if (this.isGazpromAdmin) {
            this.currentPage = 1;
        }
        this.searchUsers();
    }

    private searchUsers() {
        this.loading = true;
        //if (this.isGazpromAdmin) {
            this.searchUsersAction({ searchString: this.searchText, userId: this.getUserId, page: this.currentPage, size: this.perPage }).then(() => {
                this.loading = false;
                this.setUsersNotFoundFlag();
            });
        //} else {
        //    this.filterUsersAction({ searchString: this.searchText }).then(() => {
        //        this.loading = false;
        //        this.setUsersNotFoundFlag();
        //    });
        //}

    }

    private setUsersNotFoundFlag() {
        this.showUsersNotFoundError = (this.searchText != null && this.searchText != '') && (this.getUsers.UserList == null || this.getUsers.UserList.length == 0);
    }

    private clearSearchClicked() {
        this.searchText = "";
        this.isSearchLengthValid = true;
        this.currentPage = 1;
        this.GetUsers();
    }

    private validateSearchLength() {
        const length = this.searchText.length;
        this.isSearchLengthValid = length > 2;
        return this.isSearchLengthValid;
    }

    @Watch("searchText")
    private searchTextWatch() {
        const length = this.searchText.length;
        this.isClearSearchVisible = length > 0;
        if (length == 0) {
            this.isSearchLengthValid = true;
            this.showUsersNotFoundError = false;
        }
    }

    mounted() {
        if (this.canManageStandardUser || this.canManageExternalUser || this.canManageInternalUser) {
            this.GetUsers();
        } else {
            console.log('claim not found')
        }
    }

    public formatDate(value: string): string {
        if (value === null) {
            return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }

    private async onManageButtonClicked(value: any, id: string) {
        const doesClaimExistForSelectedUser = await this.doesClaimExistForUser({ id: id, claim: Claims.canAccessAnyRecord });
        if ((doesClaimExistForSelectedUser && (this.isGazpromAdmin || this.canManageInternalUser)) || this.canManageStandardUser || (this.isGazpromAdmin && this.canManageExternalUser && (await this.isEditingExternalTpiOrStandardUser({id: id})))) {
            this.saveSelectedUserIdForManageAction({ userId: id });
            this.$emit("manageButtonClicked", value);
        }
    }
}
